import React from "react";

export const anonymousUser = {
    id: -1,
    username: "guest",
    password: "",
    name: "Guest",
    status: 1
}

export const UserContext = React.createContext<User>(anonymousUser)

export const UserPermissions = React.createContext<string[]>([])