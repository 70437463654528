import axios from "@/axios";


export async function loadReportFinancialUserTotal({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const sd = sp.get("sd")
    const ed = sp.get("ed")
    let resp_users = await axios.get<User[]>('/user/list/all?sort=id')

    let users: User[] = []
    if (resp_users.isSuccess) {
        users = resp_users.data
    }

    let report: ReportUserFinancialTotal[] = []
    let resp =  await axios.get<ReportUserFinancialTotal[]>(`/report/user/financial/total?sd=${sd}&ed=${ed}`)
    if (resp.isSuccess) {
        report = resp.data
    }

    return {
        users,
        report
    }
}

export async function loadReportFinancialDaily({request}: {request: Request}){
    const sp = new URL(request.url).searchParams
    const year = sp.get("year")
    const month = sp.get("month")

    let resp_users = await axios.get<User[]>('/user/list/all?sort=id')

    let users: User[] = []
    if (resp_users.isSuccess) {
        users = resp_users.data
    }

    let user_id = parseInt(sp.get('user_id') || '0')
    if (!user_id) {
        user_id = users[0].id
    }

    let report: ReportUserFinancialDaily[] = []
    let resp =  await axios.get<ReportUserFinancialDaily[]>(`/report/user/financial/daily/${user_id}?year=${year}&month=${month}`)
    if (resp.isSuccess) {
        report = resp.data
    }

    return {
        users,
        report
    }
}

export async function loadReportClientFinancialTotal({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const sd = sp.get("sd")
    const ed = sp.get("ed")
    let resp_clients = await axios.get<Client[]>('/client/list/all?sort=id')

    let clients: Client[] = []
    if (resp_clients.isSuccess) {
        clients = resp_clients.data
    }

    let report: ReportClientFinancialTotal[] = []
    let resp =  await axios.get<ReportClientFinancialTotal[]>(`/report/client/financial/total?sd=${sd}&ed=${ed}`)
    if (resp.isSuccess) {
        report = resp.data
    }

    return {
        clients,
        report
    }
}