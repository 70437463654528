import {useCallback, useContext, useMemo} from "react";
import {UserPermissions} from "@/context/User";


type Perm = string | ((...perms: Perm[])=> boolean)

function usePermission() {
    const permissions = useContext(UserPermissions)

    const permByKey = useMemo(() => {
        return permissions.reduce<Record<string, boolean>>((acc, cur) => {
            acc[cur] = true
            return acc
        }, {})
    }, [permissions.length])

    const allow = useCallback<(...perms: Perm[]) => boolean>((...perms) => {
        if (permissions.length === 1 && permissions[0] === "*") {
            return true
        }

        for (const v of perms) {
            if (typeof v === "string") {
                if (v.indexOf(":") >= 0) {
                    let [tableName, actions] = v.split(":")
                    let found = false

                    if (actions === "@") {
                        actions = "list,post,patch,delete,owner_list,owner_post,owner_patch,owner_delete"
                    }

                    for(const a of actions.split(",")) {
                        const key = `${tableName}_${a}`
                        if (typeof permByKey[key] !== "undefined" && permByKey[key]) {
                            found = true
                        }
                    }

                    if (!found) {
                        return false
                    }
                } else {
                    if (typeof permByKey[v] === "undefined" || !permByKey[v]) {
                        return false
                    }
                }
            } else if (typeof v === "function") {
                if (!v()) {
                    return false
                }
            } else {
                return false
            }
        }

        return true
    }, [permByKey, permissions.length]);

    const allowOr = useCallback<(...perms: Perm[]) => boolean>((...perms) => {
        if (permissions.length === 1 && permissions[0] === "*") {
            return true
        }

        for (const v of perms) {
            if (typeof v === "string") {
                if (v.indexOf(":") >= 0) {
                    let [tableName, actions] = v.split(":")
                    if (actions === "@") {
                        actions = "list,post,patch,delete,owner_list,owner_post,owner_patch,owner_delete"
                    }
                    for(const a of actions.split(",")) {
                        const key = `${tableName}_${a}`
                        if (typeof permByKey[key] !== "undefined" && permByKey[key]) {
                            return true
                        }
                    }
                } else {
                    if (typeof permByKey[v] !== "undefined" && permByKey[v]) {
                        return true
                    }
                }
            } else if (typeof v === "function") {
                if (v()) {
                    return true
                }
            } else {
                return false
            }
        }

        return false
    }, [permByKey, permissions.length]);

    return {
        allow,
        allowOr
    }
}

export default usePermission
