import axios from "@/axios";


export async function loadDashboardInfo() {
    let balance: UserBalance[]  = []
    let widgets: Widget[] = []

    let respBalance = await axios.get<UserBalance[]>('/user/balance')
    if (respBalance.isSuccess) {
        balance = respBalance.data
    }

    let respWidgets = await axios.get<Widget[]>('/widget/list/all')
    if (respWidgets.isSuccess) {
        widgets = respWidgets.data
    }

    return {
        balance,
        widgets
    }
}