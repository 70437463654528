import axios from "@/axios";

export async function loadRegions({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const name = sp.get("name") || ""

    let resp = await axios.get<Region[]>(`region/list?page=${page}&name=${name}`)

    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadRegion({params}: {params:any}) {
    return await axios.get<Region>(`region/${params.id}`)
}