import axios from "@/axios";


export async function loadParameters({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const obj = sp.get("object") || ""
    const title = sp.get("title") || ""

    let resp = await axios.get<Parameter[]>(`/parameter/list?page=${page}&title=${title}&object=${obj}`)

    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadParameter({params}: {params:any}) {
    return await axios.get<Parameter>(`/parameter/${params.id}`)
}