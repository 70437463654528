import axios from "@/axios";


export async function loadForms({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const name = sp.get("name") || ""

    let resp = await axios.get<Form_[]>(`/form/list?page=${page}&name=${name}`)
    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadForm({params}: {params:any}) {
    return await axios.get<Form_>(`/form/${params.id}`)
}

export async function loadFormDataList({request, params}: {request: Request, params: any}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1

    let resp = await axios.get<FormData_[]>(`/form/data/${params.fid}/list?page=${page}`)
    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadFormData({params}: {params: any}) {
    return await axios.get<FormData_>(`/form/data/${params.id}`)
}