import {Link} from "react-router-dom";
import {ArrowLeft, PlusCircle} from "react-bootstrap-icons";
import {useTranslation} from "react-i18next";
import useCurrentPage from "@/hooks/useCurrentPage";
import usePermission from "@/hooks/usePermission";
import {ReactNode} from "react";

type RenderChildren = ((v: ReactNode, allowPost: boolean) => JSX.Element)
type Props = {
    children?: ReactNode|RenderChildren
    tableName?: string|string[]
    isAdd?: boolean
    title: string
    backUri?: string
}

function PageHeaderButton({isAdd, ch, backUri, tableName}: Omit<Props, "children"> & {ch?: ReactNode|RenderChildren}) {
    const {allow, allowOr} = usePermission()
    const {t} = useTranslation()
    const cp = useCurrentPage()

    let allowPost = true
    if (tableName) {
        if (Array.isArray(tableName)) {
            if (!allowOr(...tableName)) {
                allowPost = false
            }
        } else {
            if (!allow(`${tableName}_post`)) {
                allowPost = false
            }
        }
    }

    function defaultNode() {
        if (isAdd) {
            if (!allowPost) {
                return <></>
            }

            return <Link to={cp.pageAdd} className="btn btn-success ms-auto text-nowrap" relative="path">
                <PlusCircle className="me-1" />
                {t("add")}
            </Link>
        }

        return <Link to={(backUri ?? cp.pageList)} className="btn btn-success ms-auto text-nowrap" relative="path">
            <ArrowLeft className="me-1"/>
            {t("back")}
        </Link>
    }

    if (ch) {
        if (typeof ch === "function") {
            return ch(defaultNode(), allowPost)
        }
        return <>{ch}</>
    }

    return defaultNode()
}

function PageHeader({title, isAdd, children, backUri, tableName}: Props) {

    return <>
        <div className="d-flex align-items-center">
            <h4 className="mb-0 text-break me-2">{title}</h4>
            <PageHeaderButton title={title} isAdd={isAdd} ch={children} backUri={backUri} tableName={tableName} />
        </div>
        <hr/>
    </>
}

export default PageHeader