
import axios from "@/axios";


export async function loadUsers({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const username = sp.get("username") || ""
    const name = sp.get("name") || ""

    let resp = await axios.get<User[]>(`/user/list?page=${page}&username=${username}&name=${name}`)
    if (!resp.isSuccess) {
        return resp
    }

    return resp
}

export async function loadUser({params}: {params:any}) {
    return await axios.get<User>(`/user/${params.id}`)
}

export async function loadUserRoles({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const name = sp.get("name") || ""

    let resp = await axios.get<User[]>(`/user/role/list?page=${page}&name=${name}`)
    if (!resp.isSuccess) {
        return resp
    }

    return resp
}

export async function loadUserRole({params}: {params:any}) {
    return await axios.get<Role>(`/user/role/${params.id}`)
}

export async function loadUserOtp() {
    return await axios.get<UserOtp>("/user/otp")
}