import axios from "@/axios";

export async function loadProductTypes({request}: { request: Request }) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const name = sp.get("name") || ""

    let resp = await axios.get<ProductType[]>(`/product/type/list?page=${page}&name=${name}`)
    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadProductType({params}: { params: any }) {
    return await axios.get<ProductType>(`/product/type/${params.id}`)
}

export async function loadProductUnits({request}: { request: Request }) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const name = sp.get("name") || ""

    let resp = await axios.get<ProductUnit[]>(`/product/unit/list?page=${page}&name=${name}`)

    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadProductUnit({params}: { params: any }) {
    return await axios.get<ProductUnit>(`/product/unit/${params.id}`)
}

export async function loadProducts({request}: { request: Request }) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const name = sp.get("name") || ""
    const vendor_code = sp.get("vendor_code") || ""
    const manufacturer_id = sp.get("manufacturer_id") || ""
    const unit_id = sp.get("unit_id") || ""
    const type_id = sp.get("type_id") || ""

    let resp = await axios.get<Product[]>(`/product/list?page=${page}&name=${name}&vendor_code=${vendor_code}&manufacturer_id=${manufacturer_id}&unit_id=${unit_id}&type_id=${type_id}`)

    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadProduct({params}: { params: any }) {
    let product = null;
    if (params.id) {
        product = await axios.get<Product>(`/product/${params.id}`)
    }

    let manufacturers = await axios.get<Manufacturer[]>("/manufacturer/list/all")
    let units = await axios.get<ProductUnit[]>("/product/unit/list/all")
    let types = await axios.get<ProductType[]>("/product/type/list/all")

    return {
        product,
        manufacturers,
        units,
        types
    }
}

export async function loadProductOnly({params}: { params: any }) {
    return await axios.get<Product>(`/product/${params.id}`)
}

export function loadProductOperations(action: string) {
    return async ({params, request}: { params: any, request: Request }) =>  {
        const sp = new URL(request.url).searchParams
        const page = parseInt(sp.get("page") || "1") || 1
        const user_id = sp.get("user_id") || ""
        const manufacturer_id = sp.get("manufacturer_id") || ""
        const client_id = sp.get("client_id") || ""
        const product_id = sp.get("product_id") || ""

        let resp = await axios.get<Product>(`/product/operation/${action}/${params.id}/list?page=${page}&&user_id=${user_id}&product_id=${product_id}&manufacturer_id=${manufacturer_id}&client_id=${client_id}`)
        if (!resp.isSuccess) {
            return false
        }

        return resp
    }
}

export async function loadProductOperation({params}: { params: any }) {
    let resp = await axios.get<ProductTransaction>(`/product/operation/${params.id}`)
    return {
        pt: resp.isSuccess ? resp.data : null,
        extra: resp.isSuccess ? resp.extra : null
    }
}