import axios from "@/axios";
import DeleteConfirm from "@/pages/DeleteConfirm";
import {useContext} from "react";
import {FormContext} from "@/context/Form";


function FormDelete() {
    const {setForms} = useContext(FormContext)

    const onSuccess = async () => {
        let resp = await axios.get<Form_[]>("/form/list/all")
        if (resp.isSuccess) {
            setForms(resp.data)
        }
    }

    return <>
        <DeleteConfirm onSuccess={onSuccess}/>
    </>
}

export default FormDelete
