import {useLoaderData, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import {Trash} from "react-bootstrap-icons";
import axios from "@/axios";
import PageHeader from "@/components/PageHeader";
import useCurrentPage from "@/hooks/useCurrentPage";
import {ReactNode} from "react";

type Props = {
    onSuccess?: () => Promise<void>
    backUri?: string
    children?: ReactNode
    showHint?: boolean
}

function DeleteConfirm({onSuccess, backUri, children, showHint=true}: Props) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const data = useLoaderData() as Awaited<ApiResponse<Record<string, string>>>
    const cp = useCurrentPage()

    if (cp.id === 0) {
        return <>
            Not match
        </>
    }

    const handleDelete = () => {
        (async () => {
            let resp = await axios.delete(`${cp.key}/${cp.id}`)
            if (resp.isSuccess) {
                if (onSuccess) {
                    await onSuccess()
                }

                navigate(backUri ?? cp.pageList, {
                    state: {
                        alert: t("successfully_deleted")
                    }
                })
            }
        })()
    }

    return <>
        <PageHeader title={t(`${cp.langKey}.delete`)} backUri={backUri} />

        {children}

        {showHint && <p>{t(`${cp.langKey}.delete_hint`, data.data)}</p>}
        <Button variant="danger" onClick={handleDelete}>
            <Trash className="me-1"/>
            {t("delete")}
        </Button>
    </>
}

export default DeleteConfirm