import axios from "@/axios";

export async function loadClientTypes({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const parent_id = parseInt(sp.get("parent_id") || "0") || 0
    const name = sp.get("name") || ""

    let resp = await axios.get<ClientType[]>(`/client/type/list?page=${page}&parent_id=${parent_id}&name=${name}`)
    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadClientType({params}: {params:any}) {
    return await axios.get<ClientType>(`/client/type/${params.id}`)
}

export async function loadClients({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const name = sp.get("name") || ""
    const phone = sp.get("phone") || ""
    const region_id = sp.get("region_id") || ""
    const type_id = sp.get("type_id") || ""

    let resp = await axios.get<Manufacturer[]>(`/client/list?page=${page}&name=${name}&region_id=${region_id}&phone=${phone}&type_id=${type_id}`)

    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadClient({params}: {params:any}) {
    let client = null;
    if (params.id) {
        client = await axios.get<Client>(`client/${params.id}`)
    }

    let regions = await axios.get<Region[]>('/region/list/all')
    let types = await axios.get<Region[]>('/client/type/path/all')

    return {
        client,
        regions,
        types
    }
}

export async function loadClientSingle({params}: {params:any}) {
    return await axios.get<Manufacturer>(`client/${params.id}`)
}