import {useLocation, useSearchParams} from "react-router-dom";

export type CurrentPage = {
    id: number
    key: string
    langKey: string
    isDelete: boolean
    isList: boolean
    isAdd: boolean
    isEdit: boolean
    pageList: string
    pageAdd: string
}

function useCurrentPage(): CurrentPage {
    const location = useLocation()
    const [searchParams] = useSearchParams()

    const parts = location.pathname.split("/")

    //birinchi / ni olib tashlash
    parts.shift()

    let data: CurrentPage = {
        id: 0,
        key: "",
        langKey: "",
        isList: parts[parts.length - 1] === "list",
        isAdd: parts[parts.length - 1] === "add" || /-add$/.test(parts[parts.length - 1]),
        isDelete: parts[parts.length - 2] === "delete" || /-delete$/.test(parts[parts.length - 1]),
        isEdit: parts[parts.length - 2] === "edit" || /-edit$/.test(parts[parts.length - 1]),
        pageList: "",
        pageAdd: "",
    }

    if (data.isList || data.isAdd) {
        parts.pop()
    } else if (data.isDelete || data.isEdit) {
        data.id = parseInt(parts[parts.length - 1]) || 0
        parts.pop()
        parts.pop()
    }

    data.key = parts.join("/")
    data.langKey = parts.join(".")
    data.pageList = `/${data.key}/list?${searchParams.toString()}`
    data.pageAdd = `/${data.key}/add?${searchParams.toString()}`

    return data
}

export default useCurrentPage
