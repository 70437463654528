import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


async function loadI18n(siteId: number) {

    let i18nInstance = i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next) // bind react-i18next to the instance
        .init({
            ns: [`translation_${siteId}`, 'translation'],
            defaultNS: `translation_${siteId}`,
            fallbackNS: 'translation',

            fallbackLng: 'ru',
            debug: false,

            interpolation: {
                escapeValue: false, // not needed for react!!
            },
            backend: {
                loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?_=${process.env.REACT_APP_BUILD_TIMESTAMP}`,
            },
            detection: {
                order: ['localStorage'],
            }
        });


    await i18nInstance
}

export default loadI18n