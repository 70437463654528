import axios from "@/axios";

export async function loadFinancialAccounts({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const name = sp.get("name") || ""

    let resp = await axios.get<FinancialAccount[]>(`/financial/list?page=${page}&name=${name}`)

    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadFinancialAccount({params, request}: {params:any, request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const user_id = parseInt(sp.get("user_id") || "0") || 0
    const client_id = parseInt(sp.get("client_id") || "0") || 0
    const uid = sp.get("uid")
    let account = await axios.get<FinancialAccount>(`/financial/${params.id}`)
    let transactions: ApiResponse<FinancialTransaction[]> | undefined = undefined

    if (account.isSuccess) {
        let resp = await axios.get<FinancialTransaction[]>(`/financial/operation/${account.data.id}/list?page=${page}&user_id=${user_id}&client_id=${client_id}&uid=${uid}`)
        if (resp.isSuccess) {
            transactions = resp
        }
    }

    return {
        account,
        transactions
    }
}

export async function loadFinancialAccountSingle({params}: {params:any}) {
    return await axios.get<FinancialAccount>(`/financial/${params.id}`)
}

export async function loadFinancialTypes({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const parent_id = parseInt(sp.get("parent_id") || "0") || 0
    const name = sp.get("name") || ""

    let resp = await axios.get<FinancialType[]>(`/financial/type/list?page=${page}&parent_id=${parent_id}&name=${name}`)

    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadFinancialType({params}: {params:any}) {
    return await axios.get<FinancialType>(`/financial/type/${params.id}`)
}

export async function loadFinancialOperation({params}: {params: any}) {
    return await axios.get<FinancialTransaction>(`/financial/operation/${params.id}`)
}

export async function loadExchangeData() {
    let typesResp = await axios.get<FinancialType[]>("/financial/type/path/all")

    let types: FinancialType[] = []
    if (typesResp.isSuccess) {
        types = typesResp.data
    }

    return {
        types
    }
}

export async function loadBetweenData() {
    let resp = await axios.get<User[]>('/user/list/all')
    return {
        users: resp.isSuccess ? resp.data : []
    }
}