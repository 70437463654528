import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import i18n from "i18next";

let token = localStorage.getItem("token")

const instance = axios.create({
    baseURL: '/api',
    timeout: 20000,
    headers: {
        'Authorization': token ? `Bearer ${token}` : null
    }
})

function updateToken(resp: AxiosResponse) {
    let token = resp.headers['x-token']
    if (token) {
        localStorage.setItem('token', token)
        //Bu SessionCountdown da update qilinadi
        // instance.defaults.headers['Authorization'] = `Bearer ${token}`

        //SessionCountdown da ishlatilgan
        document.body.dispatchEvent(new Event("change-token"))
    }
}

instance.interceptors.response.use((response) => {
    let data = {
        isSuccess: true,
        isError: false,
        isFail: false,
        status: response.status,
        isUnauthorized: false,
        error: "",
        errors: {},
        extra: response.data.extra ?? {},
        paginator: response.data.paginator ?? {},
        data: response.data.data ?? {}
    }

    updateToken(response)

    return {...response, data: data}
}, (err: Error | AxiosError) => {
    let data = {
        isSuccess: false,
        isError: false,
        isFail: false,
        status: 0,
        isUnauthorized: false,
        error: "",
        errors: {},
        paginator: {},
        extra: {},
        data: {}
    }

    if (axios.isAxiosError(err) && err.response) {
        data.status = err.response.status

        if (err.response.status === 401) {
            data.isUnauthorized = true
        }

        if (!err.response.data.errors) {
            data.isError = true
            data.error = err.response.data.error ?? err.message
        } else {
            data.isFail = true
            data.errors = err.response.data.errors
        }

        updateToken(err.response)
    } else {
        data.isError = true
        data.error = err.message
    }

    if (data.isError) {
        if (data.error.startsWith("t.")) {
            alert(i18n.t(data.error.substring(2)))
        } else {
            alert(data.error)
        }
    }

    if (data.isUnauthorized) {
        localStorage.removeItem("token")
        if (window.location.pathname !== "/") {
            window.location.href = "/"
        }
    }

    return {data: data}
})


// const inst = <T>(cfg: AxiosRequestConfig) => instance.request<any, Response<T>>(cfg)
let _axios = {
    get: async <T, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> => {
        const r = await instance.get<T, AxiosResponse<ApiResponse<T>>, D>(url, config);
        return r.data;
    },
    post: async <T, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> => {
        const r = await instance.post<T, AxiosResponse<ApiResponse<T>>, D>(url, data, config);
        return r.data;
    },
    patch: async <T, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> => {
        const r = await instance.patch<T, AxiosResponse<ApiResponse<T>>, D>(url, data, config);
        return r.data;
    },
    delete: async <T, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> => {
        const r = await instance.delete<T, AxiosResponse<ApiResponse<T>>, D>(url, config);
        return r.data;
    },
    defaults: instance.defaults
}

export default _axios

