import React from 'react';
import ReactDOM from 'react-dom/client';

import i18n from "./i18n"
import _i18n from "i18next"

import '@/assets/scss/bootstrap.scss'
import '@/assets/scss/styles.scss'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

import {
    createBrowserRouter,
    createRoutesFromElements, Route,
    RouterProvider,
} from "react-router-dom";

import {anonymousUser, UserContext, UserPermissions} from "@/context/User";
import axios from "@/axios";

import {loadManufacturer, loadManufacturers} from "@/pages/manufacturer/loaders";

import {
    loadProduct,
    loadProductOnly, loadProductOperation, loadProductOperations,
    loadProducts,
    loadProductType,
    loadProductTypes, loadProductUnit,
    loadProductUnits
} from "@/pages/product/loaders";

import {loadSalepoint, loadSalepoints} from "@/pages/salepoint/loaders";
import {loadRegion, loadRegions} from "@/pages/region/loaders";
import {loadClient, loadClients, loadClientSingle, loadClientType, loadClientTypes} from "@/pages/client/loaders";
import {
    loadBetweenData,
    loadExchangeData,
    loadFinancialAccount,
    loadFinancialAccounts,
    loadFinancialAccountSingle, loadFinancialOperation, loadFinancialType,
    loadFinancialTypes
} from "@/pages/financial/loaders";

import {loadCbuRates} from "@/pages/settings/loaders";
import Decimal from "decimal.js-light";
import {loadUser, loadUserOtp, loadUserRole, loadUserRoles, loadUsers} from "@/pages/user/loaders";
import {
    loadReportClientFinancialTotal,
    loadReportFinancialDaily,
    loadReportFinancialUserTotal
} from "@/pages/report/loader";
import {numberFormats} from "@/consts/NumberFormats";
import {loadDashboardInfo} from "@/pages/home/loaders";
import {loadParameter, loadParameters} from "@/pages/parameter/loaders";
import {loadSystemLogs} from "@/pages/system/loaders";
import moment from "moment";
import {loadForm, loadFormData, loadFormDataList, loadForms} from "@/pages/form/loaders";
import FormDelete from "@/pages/form/FormDelete";
import {loadWidget, loadWidgets} from "@/pages/widget/loaders";

const App = React.lazy(() => import("@/pages/App"))
const NotFound = React.lazy(() => import("@/pages/NotFound"))

const HomeIndex = React.lazy(() => import("@/pages/home/HomeIndex"))
const AuthLogin = React.lazy(() => import("@/pages/auth/AuthLogin"))
const DeleteConfirm = React.lazy(() => import("@/pages/DeleteConfirm"))

const ManufacturerList = React.lazy(() => import("@/pages/manufacturer/ManufacturerList"))
const ManufacturerEdit = React.lazy(() => import("@/pages/manufacturer/ManufacturerEdit"))

const ProductUnitList = React.lazy(() => import("@/pages/product/ProductUnitList"))
const ProductUnitEdit = React.lazy(() => import("@/pages/product/ProductUnitEdit"))

const ProductTypeList = React.lazy(() => import("@/pages/product/ProductTypeList"))
const ProductTypeEdit = React.lazy(() => import("@/pages/product/ProductTypeEdit"))

const ProductList = React.lazy(() => import("@/pages/product/ProductList"))
const ProductEdit = React.lazy(() => import("@/pages/product/ProductEdit"))

const SalePointList = React.lazy(() => import("@/pages/salepoint/SalepointList"))
const SalePointEdit = React.lazy(() => import("@/pages/salepoint/SalepointEdit"))
const SalePointDelete = React.lazy(() => import("@/pages/salepoint/SalePointDelete"))

const ProductOperation = React.lazy(() => import("@/pages/product/ProductOperation"))
const ProductOperationDelete = React.lazy(() => import("@/pages/product/ProductOperationDelete"))

const RegionList = React.lazy(() => import("@/pages/region/RegionList"))
const RegionEdit = React.lazy(() => import("@/pages/region/RegionEdit"))

const ClientList = React.lazy(() => import("@/pages/client/ClientList"))
const ClientEdit = React.lazy(() => import("@/pages/client/ClientEdit"))
const ClientTypeList = React.lazy(() => import("@/pages/client/ClientTypeList"))
const ClientTypeEdit = React.lazy(() => import("@/pages/client/ClientTypeEdit"))

const FinancialList = React.lazy(() => import("@/pages/financial/FinancialList"))
const FinancialEdit = React.lazy(() => import("@/pages/financial/FinancialEdit"))
const FinancialDelete = React.lazy(() => import("@/pages/financial/FinancialDelete"))

const FinancialTypeList = React.lazy(() => import("@/pages/financial/FinancialTypeList"))
const FinancialTypeEdit = React.lazy(() => import("@/pages/financial/FinancialTypeEdit"))

const FinancialExchange = React.lazy(() => import("@/pages/financial/FinancialExchange"))
const FinancialBetween = React.lazy(() => import("@/pages/financial/FinancialBetween"))

const FinancialOperation = React.lazy(() => import("@/pages/financial/FinancialOperation"))
const FinancialOperationDelete = React.lazy(() => import("@/pages/financial/FinancialOperationDelete"))

const UserList = React.lazy(() => import("@/pages/user/UserList"))
const UserEdit = React.lazy(() => import("@/pages/user/UserEdit"))
const UserChangePassword = React.lazy(() => import("@/pages/user/UserChangePassword"))

const UserRoleList = React.lazy(() => import("@/pages/user/UserRoleList"))
const UserRoleEdit = React.lazy(() => import("@/pages/user/UserRoleEdit"))

const UserBotConnect = React.lazy(() => import("@/pages/user/UserBotConnect"))

const SettingsEdit = React.lazy(() => import("@/pages/settings/SettingsEdit"))

const ReportUserFinancialTotal = React.lazy(() => import("@/pages/report/user/ReportUserFinancialTotal"))
const ReportUserFinancialDaily = React.lazy(() => import("@/pages/report/user/ReportUserFinancialDaily"))

const ReportClientFinancialTotal = React.lazy(() => import("@/pages/report/client/ReportClientFinancialTotal"))

const ParameterList = React.lazy(() => import("@/pages/parameter/ParameterList"))
const ParameterEdit = React.lazy(() => import("@/pages/parameter/ParameterEdit"))

const SystemLogs = React.lazy(() => import("@/pages/system/SystemLogs"))

const FormList = React.lazy(() => import("@/pages/form/FormList"))
const FormEdit = React.lazy(() => import("@/pages/form/FormEdit"))
const FormDataList = React.lazy(() => import("@/pages/form/FormDataList"))
const FormDataEdit = React.lazy(() => import("@/pages/form/FormDataEdit"))
const FormDataDelete = React.lazy(() => import("@/pages/form/FormDataDelete"))
const FormDataBatchAdd = React.lazy(() => import("@/pages/form/FormDataBatchAdd"))

const WidgetEdit = React.lazy(() => import("@/pages/widget/WidgetEdit"))
const WidgetList = React.lazy(() => import("@/pages/widget/WidgetList"))

//Decimal.set({ precision: 3 })

// eslint-disable-next-line no-extend-native
Number.prototype.round2decimal = function (): number {
    return Math.round((this.valueOf() + Number.EPSILON) * 100) / 100
}

// eslint-disable-next-line no-extend-native
Number.prototype.format = function (): string {
    return numberFormats[_i18n.language].format(this.valueOf())
}

// eslint-disable-next-line no-extend-native
String.prototype.toDecimal = function (): Decimal {
    return new Decimal(this.valueOf())
}

// eslint-disable-next-line no-extend-native
String.prototype.cleanToDecimal = function (defaultValue: string | number = 0): Decimal {
    return (this.valueOf().replaceAll(" ", "") || defaultValue.toString()).toDecimal()
}

// eslint-disable-next-line no-extend-native
String.prototype.canEdit = function (): boolean {
    const currentTime = moment().utc();
    const thisTime = moment(this.valueOf()).utc()
    const timeDifference = moment.duration(currentTime.diff(thisTime)).asHours();
    return timeDifference <= 24;
}

async function init() {
    let currentUser: User = anonymousUser;
    let userPermissions: string[] = []
    let siteId: number = 0

    if (localStorage.getItem("token")) {
        let resp = await axios.get<AuthMe>("/auth/me")
        if (resp.isSuccess) {
            siteId = resp.data.site_id
            currentUser = resp.data.user
            userPermissions = resp.data.permissions
        }
    }

    await i18n(siteId)

    // await new Promise((r) => setTimeout(r, 1000))

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

    if (currentUser.id === -1) {
        root.render(
            <React.StrictMode>
                <AuthLogin/>
            </React.StrictMode>
        )
        return
    }


    const router = createBrowserRouter(createRoutesFromElements(
        <Route path="/" element={<App/>}>
            <Route index element={<HomeIndex/>} loader={loadDashboardInfo}/>
            <Route path="manufacturer/list" element={<ManufacturerList/>} loader={loadManufacturers}/>
            <Route path="manufacturer/add" element={<ManufacturerEdit/>}/>
            <Route path="manufacturer/edit/:id" element={<ManufacturerEdit/>} loader={loadManufacturer}/>
            <Route path="manufacturer/delete/:id" element={<DeleteConfirm/>} loader={loadManufacturer}/>

            <Route path="product/unit/list" element={<ProductUnitList/>} loader={loadProductUnits}/>
            <Route path="product/unit/add" element={<ProductUnitEdit/>}/>
            <Route path="product/unit/edit/:id" element={<ProductUnitEdit/>} loader={loadProductUnit}/>
            <Route path="product/unit/delete/:id" element={<DeleteConfirm/>} loader={loadProductUnit}/>

            <Route path="product/type/list" element={<ProductTypeList/>} loader={loadProductTypes}/>
            <Route path="product/type/add" element={<ProductTypeEdit/>}/>
            <Route path="product/type/edit/:id" element={<ProductTypeEdit/>} loader={loadProductType}/>
            <Route path="product/type/delete/:id" element={<DeleteConfirm/>} loader={loadProductType}/>


            <Route path="product/list" element={<ProductList/>} loader={loadProducts}/>
            <Route path="product/add" element={<ProductEdit/>} loader={loadProduct}/>
            <Route path="product/edit/:id" element={<ProductEdit/>} loader={loadProduct}/>
            <Route path="product/delete/:id" element={<DeleteConfirm/>} loader={loadProductOnly}/>

            <Route path="product/operation/sales/:id" element={<ProductOperation action="sales"/>}
                   loader={loadProductOperations("sales")}/>
            <Route path="product/operation/income/:id" element={<ProductOperation action="income"/>}
                   loader={loadProductOperations("income")}/>
            <Route path="product/operation/daily/:id" element={<ProductOperation action="daily"/>}
                   loader={loadProductOperations("daily")}/>

            <Route path="product/operation/:action/delete/:id" element={<ProductOperationDelete/>}
                   loader={loadProductOperation}/>

            <Route path="salepoint/list" element={<SalePointList/>} loader={loadSalepoints}/>
            <Route path="salepoint/add" element={<SalePointEdit/>}/>
            <Route path="salepoint/edit/:id" element={<SalePointEdit/>} loader={loadSalepoint}/>
            <Route path="salepoint/delete/:id" element={<SalePointDelete/>} loader={loadSalepoint}/>

            <Route path="region/list" element={<RegionList/>} loader={loadRegions}/>
            <Route path="region/add" element={<RegionEdit/>}/>
            <Route path="region/edit/:id" element={<RegionEdit/>} loader={loadRegion}/>
            <Route path="region/delete/:id" element={<DeleteConfirm/>} loader={loadRegion}/>

            <Route path="client/list" element={<ClientList/>} loader={loadClients}/>
            <Route path="client/add" element={<ClientEdit/>} loader={loadClient}/>
            <Route path="client/edit/:id" element={<ClientEdit/>} loader={loadClient}/>
            <Route path="client/delete/:id" element={<DeleteConfirm/>} loader={loadClientSingle}/>

            <Route path="client/type/list" element={<ClientTypeList/>} loader={loadClientTypes}/>
            <Route path="client/type/add" element={<ClientTypeEdit/>}/>
            <Route path="client/type/edit/:id" element={<ClientTypeEdit/>} loader={loadClientType}/>
            <Route path="client/type/delete/:id" element={<DeleteConfirm/>} loader={loadClientType}/>

            <Route path="financial/list" element={<FinancialList/>} loader={loadFinancialAccounts}/>
            <Route path="financial/add" element={<FinancialEdit/>}/>
            <Route path="financial/edit/:id" element={<FinancialEdit/>} loader={loadFinancialAccountSingle}/>
            <Route path="financial/delete/:id" element={<FinancialDelete/>} loader={loadFinancialAccountSingle}/>

            <Route path="financial/type/list" element={<FinancialTypeList/>} loader={loadFinancialTypes}/>
            <Route path="financial/type/add" element={<FinancialTypeEdit/>}/>
            <Route path="financial/type/edit/:id" element={<FinancialTypeEdit/>} loader={loadFinancialType}/>
            <Route path="financial/type/delete/:id" element={<DeleteConfirm/>} loader={loadFinancialType}/>

            <Route path="financial/exchange" element={<FinancialExchange/>} loader={loadExchangeData}/>
            <Route path="financial/between" element={<FinancialBetween/>} loader={loadBetweenData}/>

            <Route path="financial/operation/:id" element={<FinancialOperation/>} loader={loadFinancialAccount}/>
            <Route path="financial/operation/delete/:id" element={<FinancialOperationDelete/>}
                   loader={loadFinancialOperation}/>

            <Route path="user/list" element={<UserList/>} loader={loadUsers}/>
            <Route path="user/add" element={<UserEdit/>}/>
            <Route path="user/edit/:id" element={<UserEdit/>} loader={loadUser}/>
            <Route path="user/delete/:id" element={<DeleteConfirm/>} loader={loadUser}/>

            <Route path="user/role/list" element={<UserRoleList/>} loader={loadUserRoles}/>
            <Route path="user/role/add" element={<UserRoleEdit/>}/>
            <Route path="user/role/edit/:id" element={<UserRoleEdit/>} loader={loadUserRole}/>
            <Route path="user/role/delete/:id" element={<DeleteConfirm/>} loader={loadUserRole}/>

            <Route path="user/change-password" element={<UserChangePassword/>}/>
            <Route path="user/bot-connect" element={<UserBotConnect/>} loader={loadUserOtp} />

            <Route path="settings" element={<SettingsEdit/>} loader={loadCbuRates}/>

            <Route path="form/list" element={<FormList/>} loader={loadForms}/>
            <Route path="form/add" element={<FormEdit/>}/>
            <Route path="form/edit/:id" element={<FormEdit/>} loader={loadForm}/>
            <Route path="form/delete/:id" element={<FormDelete/>} loader={loadForm}/>

            <Route path="form/data/:fid/list" element={<FormDataList/>} loader={loadFormDataList}/>
            <Route path="form/data/:fid/add" element={<FormDataEdit/>}/>
            <Route path="form/data/:fid/batch-add" element={<FormDataBatchAdd/>}/>
            <Route path="form/data/:fid/edit/:id" element={<FormDataEdit/>} loader={loadFormData}/>
            <Route path="form/data/:fid/delete/:id" element={<FormDataDelete/>} loader={loadFormData}/>

            <Route path="report/user/financial-total" element={<ReportUserFinancialTotal/>}
                   loader={loadReportFinancialUserTotal}/>
            <Route path="report/user/financial-daily" element={<ReportUserFinancialDaily/>}
                   loader={loadReportFinancialDaily}/>

            <Route path="report/client/financial-total" element={<ReportClientFinancialTotal/>}
                   loader={loadReportClientFinancialTotal}/>

            <Route path="parameter/list" element={<ParameterList/>} loader={loadParameters}/>
            <Route path="parameter/add" element={<ParameterEdit/>}/>
            <Route path="parameter/edit/:id" element={<ParameterEdit/>} loader={loadParameter}/>
            <Route path="parameter/delete/:id" element={<DeleteConfirm/>} loader={loadParameter}/>

            <Route path="system/logs" element={<SystemLogs/>} loader={loadSystemLogs}/>

            <Route path="widget/list" element={<WidgetList/>} loader={loadWidgets}/>
            <Route path="widget/add" element={<WidgetEdit/>}/>
            <Route path="widget/edit/:id" element={<WidgetEdit/>} loader={loadWidget}/>

            <Route path="*" element={<NotFound/>}/>
        </Route>
    ))

    root.render(
        // <React.StrictMode>
        <UserContext.Provider value={currentUser}>
            <UserPermissions.Provider value={userPermissions}>
                <RouterProvider router={router}/>
            </UserPermissions.Provider>
        </UserContext.Provider>
        // </React.StrictMode>
    );
}

init();


