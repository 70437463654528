import axios from "@/axios";


export async function loadWidgets({request}: {request: Request}) {
    const sp = new URL(request.url).searchParams
    const page = parseInt(sp.get("page") || "1") || 1
    const title = sp.get("title") || ""

    let resp = await axios.get<Widget[]>(`/widget/list?page=${page}&title=${title}`)

    if (!resp.isSuccess) {
        return false
    }

    return resp
}

export async function loadWidget({params}: {params: any}) {
    return await axios.get<Widget>(`/widget/${params.id}`)
}