import React from "react";

type FormList = {
    forms: Array<Form_>
    setForms: (data: Form_[]) => void
}

export const FormContext = React.createContext<FormList>({
    forms: [],
    setForms: _ => {},
})
